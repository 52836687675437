import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/HomePageHero.js";
import Features from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
import MainFeature from "components/features/TwoColWithButton.js";
import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
import Testimonial from "components/testimonials/SimplePrimaryBackground.js";
import FAQ from "components/faqs/TwoColumnPrimaryBackground.js";
import Footer from "components/footers/MiniCenteredFooter";
import SupertekstStudio from "images/supertekst_studio.png"
import serverSecureIllustrationImageSrc from "images/server-redundancy-illustration.svg"


export default () => {
	return (
		<AnimationRevealPage disabled={true}>
			<Hero />
			<MainFeature 
				subheading="Undertekster til video"
				heading="Brukervennlig og enkel teksting"
				imageSrc={SupertekstStudio}
				buttonRounded={false}
				description="Supertekst er svært enkelt å bruke til å tekste videoer. Etter at du har lastet opp videoen får du automatisk tekst, og du behøver kun å endre enkelte ord og setninger."
			/>
			<MainFeature 
				subheading="Diktering"
				heading="Tale-til-tekst"
				imageSrc={serverSecureIllustrationImageSrc}
				buttonRounded={false}
				textOnLeft={false}
				description="Automatisert diktering av tale er en rask og effektiv måte å konvertere tale til tekst på, og kan spare deg for tid og penger sammenlignet med manuell transkripsjon."
			/>
			<FAQ />
			<Footer />
		</AnimationRevealPage>
	);
}
