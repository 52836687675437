import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import HeaderBase, {
	LogoLink as LogoLinkBase,
	NavLinks,
	NavLink as NavLinkBase,
	PrimaryLink as PrimaryLinkBase
} from "../headers/Header.js";
import { Container as ContainerBase, ContentWithVerticalPadding, Content2Xl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import serverIllustrationImageSrc from "images/server-illustration-2.svg";

const PrimaryBackgroundContainer = tw.div`-mx-8 px-8 bg-primary-900 text-gray-100`;
const Header = tw(HeaderBase)`max-w-none -mt-8 py-8 -mx-8 px-8`;
const NavLink = tw(NavLinkBase)`lg:text-gray-100 lg:hocus:text-gray-300 lg:hocus:border-gray-100`;
const LogoLink = tw(LogoLinkBase)`text-gray-100 hocus:text-gray-300`;
const PrimaryLink = tw(PrimaryLinkBase)`shadow-raised lg:bg-primary-400 lg:hocus:bg-primary-500`;

const Container = tw(ContainerBase)``;
const Row = tw.div`flex items-center flex-col lg:flex-row`;
const Column = tw.div`lg:w-1/2`;
const TextColumn = tw.div`text-center lg:text-left`;
const IllustrationColumn = tw(Column)`mt-16 lg:mt-0 lg:ml-16`;
const Heading = tw(SectionHeading)`max-w-3xl lg:max-w-4xl lg:text-left leading-tight`;
const Description = tw(SectionDescription)`mt-4 max-w-2xl text-gray-100 lg:text-base mx-auto lg:mx-0`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-8 text-sm sm:text-base px-6 py-5 sm:px-10 sm:py-5 bg-primary-400 inline-block hocus:bg-primary-500`;
const Image = tw.img`w-144 ml-auto`

export default ({
	heading = "Automatiserte undertekster med høy kvalitet",
	description = "Vi bruker kunstig intelligens for å lage undertekster i høy kvalitet på norsk. Ved hjelp av vårt unike adaptive læringslag blir resultatene bedre og bedre jo mer du bruker verktøyet.",
	primaryButtonText = "Sett meg på venteliste",
	primaryButtonUrl = "/kontakt-oss",
	imageSrc = serverIllustrationImageSrc,
}) => {
	const logoLink = (
		<LogoLink href="/">
			<img src="/logo.png" alt="Logo" /> Supertekst
		</LogoLink>
	);
	const navLinks = [
		<NavLinks key={1}>
			<NavLink href="/kontakt-oss">Kontakt oss</NavLink>
			<PrimaryLink href="https://app.supertekst.no/">Logg inn</PrimaryLink>
			{ /*
			<NavLink href="/slik-fungerer-det">Slik fungerer det</NavLink>
			<NavLink href="/retningslinjer-for-teksting">Retningslinjer for teksting</NavLink>
			<PrimaryLink href="#">Registrer meg</PrimaryLink>
			*/ }
		</NavLinks>
	];
	return (
		<PrimaryBackgroundContainer>
			<Content2Xl>
				<Header logoLink={logoLink} links={navLinks} />
				<Container>
					<ContentWithVerticalPadding>
						<Row>
							<TextColumn>
								<Heading>{heading}</Heading>
								<Description>{description}</Description>
								<PrimaryButton as="a" href={primaryButtonUrl}>{primaryButtonText}</PrimaryButton>
							</TextColumn>
							<IllustrationColumn>
								<Image src={imageSrc} />
							</IllustrationColumn>
						</Row>
					</ContentWithVerticalPadding>
				</Container>
			</Content2Xl>
		</PrimaryBackgroundContainer>
	);
};
